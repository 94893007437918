<template>
  <q-dialog transition-show="slide-up" transition-hide="slide-down" persistent>
    <q-card class="form">
      <div class="q-pa-xs">
        <q-card-section class="row items-center q-pb-lg">
          <div
            style="font-size: 14pt"
            class="text-uppercase text-grey-8 q-mt-sm"
            v-html="$t('missions.my_progress')"
          />
          <q-space />
          <q-btn icon="close" color="red" flat round dense v-close-popup />
        </q-card-section>

        <q-separator class="q-mr-md q-ml-md" />

        <q-card-section
          class="q-pt-none q-mt-md bg-orange default-rounded q-ma-md q-pa-sm"
          v-if="
            currentStep(current, data).content.length &&
            currentStep(current, data).content[0].status ==
              'Content_to_be_improved'
          "
        >
          <div class="q-ma-sm q-mb-md bg-white q-pa-md default-rounded">
            <div
              v-html="$t('missions.content_feedback')"
              class="text-weight-bolder text-uppercase text-orange-8"
            />

            <q-separator />
            <div class="q-mt-sm">
              <q-icon name="flag" color="orange-8" style="font-size: 16pt" />
              {{
                currentStep(current, data).content[0].content_feedbacks
                  .observation
              }}
            </div>
          </div>
        </q-card-section>
        <q-card-section class="q-pt-none q-mt-md bg-grey-2 q-ma-md q-pa-md">
          <div class="q-pa-sm text-justify">
            <span
              ><q-icon name="info" size="20px" class="q-mr-sm" />
              {{ $t("missions.progress_message") }}</span
            >
          </div>

          <q-linear-progress size="30px" :value="progress" color="green">
            <div class="absolute-full flex flex-center">
              <q-badge
                color="white"
                text-color="black"
                :label="progressLabel"
              />
            </div>
          </q-linear-progress>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <q-list>
            <template v-for="(d, i) in data" :key="i">
              <hr
                class="q-mr-sm q-ml-sm q-mt-md q-mb-md"
                style="color: #e0e0e0"
              />

              <q-expansion-item
                :expand="true"
                switch-toggle-side
                :icon="getIcon(d.id, current)"
                :default-opened="current == d.id"
                :label="$t('missions.step', { value: d.sequence })"
              >
                <div>
                  <div
                    class="q-pa-md bg-grey-4 text-justify q-mr-md q-ml-md"
                    style="border-radius: 5px"
                  >
                    <div class="row q-ma-sm">
                      <q-space />
                      <q-badge
                        rounded
                        color="primary"
                        class="text-uppercase q-pa-sm"
                        :label="
                          getFinishDate(
                            d.when_date,
                            d.when_integer,
                            start_current_step
                          )
                        "
                        v-if="d.when_date || d.when_integer"
                      />
                    </div>
                    <div
                      class="q-ma-sm bg-white q-pa-md default-rounded full-height"
                    >
                      <span v-html="d.todo" />
                    </div>
                    <div class="row q-mb-md" style="margin-top: -20px">
                      <div
                        class="col-12 col-md-4 q-mt-md"
                        v-for="(t, i) in d.targets"
                        :key="i"
                      >
                        <div
                          class="q-ma-sm bg-white q-pa-md default-rounded full-height"
                        >
                          <span class="text-weight-bold"
                            ><q-icon :name="getTargetIcon(t.target)" />
                            {{ t.target }}
                          </span>
                          <div class="q-mt-sm">
                            <span v-html="t.pivot.description" />
                            <br />
                            <q-badge
                              color="grey-7"
                              class="q-pa-sm q-mt-md"
                              v-if="'hashtag' in t.pivot && t.pivot.hashtag"
                            >
                              #{{ t.pivot.hashtag }}
                            </q-badge>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <q-timeline
                    class="q-pa-md"
                    color="secondary"
                    v-if="d.content.length"
                  >
                    <uploaded-content-component
                      :content="c"
                      :index="i"
                      :key="i"
                      v-for="(c, i) in d.content"
                    />
                    <q-timeline-entry color="grey" v-if="d.content.length" />
                  </q-timeline>

                  <div
                    style="border-radius: 5px"
                    class="q-pb-xl q-pt-xl q-ma-md text-center bg-grey-3 text-uppercase text-weight-light"
                    v-if="
                      // Verifica se está no passo para que tenha a opção de enviar conteudo visivel.
                      !d.content.length && currentStep(current, data).id == d.id
                    "
                  >
                    <p>{{ $t("missions.no_contents") }}</p>
                    <q-btn
                      v-if="showUploadBtn()"
                      :icon="'upload'"
                      color="primary"
                      label="Enviar Conteúdo"
                      @click="sendContent()"
                    >
                    </q-btn>
                  </div>
                </div>
              </q-expansion-item>
            </template>
          </q-list>
        </q-card-section>
      </div>
    </q-card>
  </q-dialog>
</template>

<script setup>
import {
  getTargetIcon,
  getFinishDate,
  isMissionStepExpired,
} from "../helpers/script_helpers";
import UploadedContentComponent from "./UploadedContentComponent.vue";
import { defineProps, defineEmits } from "vue";

const props = defineProps({
  data: {
    type: Array,
    required: true,
  },
  progress: {
    type: Number,
    required: true,
  },
  progressLabel: {
    type: String,
    required: true,
  },
  current: {
    type: Number,
    required: true,
  },
  start_current_step: {
    type: Number,
    required: true,
  },
  mission: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(["sendContent"]);

const getIcon = (id, current) => {
  if (!current) return "more_horiz";
  return id < current
    ? "done"
    : id === current
    ? "account_circle"
    : "more_horiz";
};

const currentStep = (id, data) => data.find((d) => d.id === id);
const sendContent = () => emit("sendContent");

const showUploadBtn = () => {
  const currentStepData = currentStep(props.current, props.data);
  const isContentToBeImproved = currentStepData.content.length
    ? currentStepData.content[0].status === "Content_to_be_improved"
    : false;

  return isContentToBeImproved
    ? true
    : isMissionStepExpired(
        props.data.partner_start_current_step,
        props.mission.end_at,
        currentStepData.when_date,
        currentStepData.when_integer
      );
};
</script>

<style lang="scss" scoped>
.form {
  width: 1500px;
  max-width: 80vw;
  height: 90vh;
}
.mobile {
  .form {
    min-width: 98vw;
  }
}
</style>
