<template>
  <div>
    <div v-html="headContent" class="text-center q-mb-md" />

    <div class="q-mt-sm" v-for="field in fields" :key="field.name">
      <div class="q-mt-sm" v-if="shouldShowField(field)">
        <p class="input-label">{{ field.label }}</p>
        <template v-if="field.type === 'text' || field.type === 'textarea'">
          <q-input
            dense
            filled
            clearable
            :type="field.type"
            lazy-rules="ondemand"
            v-model="formData[field.name]"
            :rules="
              getCustomValidations(field.validations, field.label, field.type)
            "
          />
        </template>
        <template v-else-if="field.type === 'select'">
          <q-select
            dense
            filled
            clearable
            :options="field.options"
            v-model="formData[field.name]"
            :rules="
              getCustomValidations(field.validations, field.label, field.type)
            "
          />
        </template>
        <!-- Template para checkbox (q-option-group) -->
        <template v-else-if="field.type === 'checkbox'">
          <q-option-group
            v-model="formData[field.name]"
            :options="field.options"
            type="checkbox"
            color="black"
            :rules="
              getCustomValidations(field.validations, field.label, 'checkbox')
            "
          />
          <q-input
            style="display: none"
            :model-value="validateValue"
            :rules="[(val) => !!val || '']"
          />
          <div
            v-if="
              Array.isArray(formData[field.name]) &&
              !formData[field.name].length &&
              showErrors
            "
            class="q-pa-md bg-red-2 q-mt-sm q-mb-md"
            v-html="'Seleciona ao menos uma opção.'"
          />
        </template>
      </div>
    </div>
  </div>
  <FormActions
    :isLastStep="isLastStep"
    @next-step="onNextStep"
    :showPrevious="stepIndex !== 0"
    @go-to-previous-step="onGoToPreviousStep"
  />
</template>

<script setup>
import { ref, defineProps, defineEmits, onMounted } from "vue";
import FormActions from "../FormActions.vue";
import {
  customFields,
  formatCustomFormData,
  getCustomValidations,
} from "../../form-config/helpers";

// Definindo as propriedades recebidas pelo componente
const props = defineProps({
  fields: Array,
  headContent: String,
  stepIndex: {
    type: Number,
    required: true,
  },
  step: {
    type: String,
    required: true,
  },
  partner: {
    type: Object,
    required: true,
  },
  isLastStep: {
    type: Boolean,
    required: true,
  },
});

// Definindo os eventos emitidos pelo componente
const emit = defineEmits(["getPayload", "goToPreviousStep"]);

// Estado local para armazenar os dados do formulário
const formData = ref({});
const validateValue = ref(null);
const showErrors = ref(false);

const initializeFormData = (fields) => {
  fields.forEach((field) => {
    if (field.type === "checkbox") {
      // Inicializa como array ou usa os dados prévios se existirem
      formData.value[field.name] = props.partner[field.name] || [];
    } else if (field.type === "select") {
      // Para selects, busca o objeto em options cujo value corresponde ao valor em partner
      // Ao contrário do comum, aqui é usado o label, para que assim o select seja preenchido com o valor selecionado
      // quando for atualizado e o admin poder consultar

      const selectedOption = field.options.find((option) =>
        customFields.includes(field.name)
          ? option.label
          : option.value === props.partner[field.name]
      );

      formData.value[field.name] = selectedOption ? selectedOption : undefined;
    } else {
      // Para outros tipos de campos, preenche com os dados prévios, se disponíveis
      formData.value[field.name] = props.partner[field.name] || "";
    }
  });
};

// Função para validar se todos os checkboxes possuem ao menos um valor selecionado
const validateCheckboxes = () => {
  return props.fields.every((field) => {
    if (field.type === "checkbox") {
      if (formData.value[field.name].length > 0) {
        validateValue.value = formData.value[field.name];
        showErrors.value = false;
        return true;
      } else {
        showErrors.value = true;
        return false;
      }
    }
    return true; // Outros campos são considerados válidos por padrão
  });
};

// Lógica para checar condições de exibição dos campos
const shouldShowField = (field) => {
  if (!field.condition) return true; // Sem condição, o campo sempre aparece
  const [targetField, operator, value] = field.condition;
  const targetValue = formData.value[targetField];
  // Verifica se o valor atual do campo alvo é válido (não null ou vazio)
  if (
    targetValue === null ||
    (Array.isArray(targetValue) && targetValue.length === 0)
  ) {
    return false;
  }
  if (targetValue)
    switch (operator) {
      case "equal":
        return typeof targetValue == "object"
          ? targetValue.value.toString() === value.toString()
          : targetValue.toString() === value.toString();
      case "different":
        return typeof targetValue == "object"
          ? targetValue.value.toString() !== value.toString()
          : targetValue.toString() !== value.toString();
      case "in":
        return Array.isArray(value) && value.includes(targetValue);
      case "not in":
        return Array.isArray(value) && !value.includes(targetValue);
      default:
        return true;
    }
  return false;
};

const onGoToPreviousStep = () => emit("goToPreviousStep");

// Função chamada ao prosseguir para o próximo passo
const onNextStep = () => {
  showErrors.value = !validateCheckboxes(); // Exibe mensagens de erro se a validação falhar
  if (validateCheckboxes())
    emit("getPayload", formatCustomFormData(formData.value));
};

onMounted(() => {
  // Inicializa o formData para que o campo checkbox seja um array
  initializeFormData(props.fields);
});
</script>
