export default {
  change_photo: "Mudar foto",
  delete_photo: "Excluir foto",
  change_password: "Alterar senha",
  change_email: "Dados de Contato",
  contact_details_message:
    "Mantenha seus dados atualizados para que possamos entrar em contato com você.",
  change_email_message:
    "Por medida de segurança, o e-mail somente pode ser alterado mediante a alteração do e-mail anterior. Caso você não consiga alterar seu e-mail, você deve validar o seu e-mail antes de alterá-lo, se estiver com problemas entre em contato com o <b>{value}</b>",
  account: "Meus Dados",
  address: "Endereço",
  bank_account: "Dados Bancários",
  change_store_name: "Nome do seu espaço",
  you_store: "link da sua loja atual: ",
  social_account: "Dados Sociais",
  documents: "Documentos Submetidos",
  social_account_alert: `Os dados salvos nesta aba 'Dados Sociais' - <b>inclusive WhatsApp</b> -
  poderão ser compartilhados publicamente na sua área de parceiro.
  Clientes potenciais poderão entrar em contato com você. Somente digite
  esses dados se estiver de acordo com o compartilhamento com clientes.`,
  more_info: "Mais Informações",
  pic_dimensions: "Dimensões recomendadas: 300x300px",
  pic_size: "Tamanho máximo: 1MB",
  my_docs: {
    disclaimer:
      "Submeta os documentos necessários para validação de seu cadastro.",
    docs: "DOCUMENTOS",
    doc_type: "Tipo: ",
    file_name: `<b>Nome do Arquivo:</b> {description}`,
    created_at: "Data",
    observation: "Observações:",
    exedeed_limit: "O arquivo excede o tamanho máximo de {value}MB.",
    send_again:
      "Caso deseje reenviar algum arquivo, clique no botão excluir e faça o upload novamente.",

    // docs side
    modal_title: "Enviar documento",
    no_docs: "Você já enviou todos os documentos necessários.",
    no_docs_submitted: "Você ainda não enviou nenhum documento.",
    tip_1: "Selecione o tipo de documento que deseja realizar upload;",
    tip_2: "É permitido apenas um upload por tipo de documento.",
    select: "Selecione um tipo de documento",
    select_file: "Selecione um arquivo",
    message: "O tamanho máximo do arquivo é de {value}MB",
    success: "Documento enviado com sucesso!",
  },
  company_data: {
    title: "Dados da Empresa",
    disclaimer: `Informações da empresa onde você trabalha. Essas informações serão utilizadas para fins de validação de seu cadastro.`,
  },
  pending_docs_message:
    "Olá, Seja bem-vindo(a)! Para prosseguir, será necessário enviar seus documentos para que possamos validar seu cadastro.",

  no_pending_docs: "Não há documentos pendentes.",

  analyzing_data: "Dados em análise",
  update_data: "Atualizar dados",
  little_time: "Falta pouco!",
  data_analyzing:
    "Agora seus dados estão em análise, fique atento ao seu e-mail, pois te notificaremos assim que forem validados.",

  perfil_analyzing:
    "Estamos avaliando seus dados e em breve retornaremos. Fique atento ao seu E-mail.",

  validate_or_change: {
    email: {
      verify: "Verificar Email",
      change: "Alterar Email",
      label_step_1:
        "Insira no campo abaixo o novo endereço de email que deseja utilizar.",
      label_step_2:
        "Um email com o código de verificação foi enviado para o endereço <b>{value}</b>.",

      verified: "Email verificado com sucesso!",
      updated: "Email atualizado com sucesso!",
    },
    celular: {
      verify: "Verificar Celular",
      change: "Alterar Celular",
      label_step_1:
        "Insira no campo abaixo o novo número de celular que deseja utilizar.",
      label_step_2:
        "Um código de verificação foi enviado via SMS para o seguinte número de celular: <b>{value}</b>",

      verified: "Celular verificado com sucesso!",
      updated: "Celular atualizado com sucesso!",
    },

    invalid_code: "Código inválido.",
    resended_code: "Código reenviado com sucesso!",
  },

  association_success: "Inscrição realizada com sucesso!",

  cpf_is_required: "O campo CPF é requerido",
  cpf_is_invalid: "CPF inválido",

  parameterized_registration: {
    pf_pj_helper:
      "<b>Se você optar pelo modelo de recebimento como pessoa física ou não puder gerar uma nota fiscal, usaremos esses dados para o pagamento como pessoa física.",
    select_pix_or_account:
      "Selecione a Conta ou Pix para recebimento em sua conta:",
  },
};
