<template>
  <div class="q-mr-lg q-ml-lg text-justify">
    <h6 class="text-subtitle2" v-html="$t('login.recover.titleConfirm')" />
    <p class="text-subtitle3" v-html="$t(text_orientation)" />
    <p
      class="text-subtitle3 q-mb-lg"
      v-html="$t('login.recover.orientation_text')"
    />
  </div>

  <Form
    v-if="!fixedCode"
    class="q-gutter-md"
    :initial-values="initialValues"
    :validation-schema="schema"
    @submit="onSubmit"
  >
    <q-card-section>
      <Field
        name="code"
        v-slot="{ errorMessage, value, field }"
        v-if="in_request_code == false"
      >
        <q-input
          autocomplete="on"
          filled
          :label="labels.code"
          :model-value="value"
          v-bind="field"
          :error-message="errorMessage"
          :error="!!errorMessage"
        />
      </Field>
      <div class="text-right q-pt-sm q-pb-md">
        <q-btn
          color="black"
          type="submit"
          :label="$t('login.recover.submit')"
          :loading="in_request"
          :disable="in_request"
        />
      </div>
    </q-card-section>
  </Form>

  <q-card-section class="q-gutter-md" v-else>
    <q-form @submit="onSubmit()" greedy class="q-mt-md">
      <div class="q-pb-sm">
        <q-input
          filled
          :label="labels.code"
          v-model="fixedCode"
          :disable="fixedCode ? true : false"
        />
        <div
          class="text-caption text-red-8 q-ml-md q-mt-xs"
          v-if="errorMessage"
        >
          {{ errorMessage }}
        </div>
      </div>
      <div class="text-right q-pt-md q-pb-md">
        <q-btn
          color="black"
          type="submit"
          :label="$t('login.recover.submit')"
          :loading="in_request"
          :disable="in_request"
        />
      </div>
    </q-form>
  </q-card-section>
</template>

<script>
import { useActions, useGetters } from "vuex-composition-helpers";
import { rules, labels } from "@/shared/form-presets";
import { useRouter, useRoute } from "vue-router";
import { Field, Form } from "vee-validate";
import { Notify } from "quasar";
import * as yup from "yup";
import { ref, onMounted } from "vue";

export default {
  name: "RecoverConfirm",

  components: {
    Field,
    Form,
  },

  setup() {
    const router = useRouter(),
      route = useRoute(),
      df_text = "login.recover.orientation",
      text_orientation = ref(df_text);
    const fixedCode = ref(null);
    const errorMessage = ref(null);

    const in_request = ref(false),
      in_request_code = ref(false),
      schema = (() => {
        const { code } = rules;
        return yup.object({ code });
      })(),
      initialValues = {};

    const { sendCodeAction } = useActions({
        sendCodeAction: "auth/sendCodeAction",
      }),
      { partner } = useGetters({
        partner: "partner/getPartner",
      });

    if (route.query.channel && route.query.channel) {
      text_orientation.value =
        {
          sms: "login.recover.orientation_wpp",
          whatsapp: "login.recover.orientation_sms",
        }[route.query.channel] || df_text;
    }

    const onSubmit = (values, actions) => {
      errorMessage.value = null;

      in_request.value = true;
      sendCodeAction({
        code: fixedCode.value ? fixedCode.value : values.code,
        email: route.params.email,
        slug: route.params.slug,
      })
        .then(() => {
          router.push({
            name: "recover.password.change",
            params: {
              code: fixedCode.value ? fixedCode.value : values.code,
              email: route.params.email,
            },
          });
        })
        .catch(({ errors, message }) => {
          errorMessage.value = errors.code[0];

          actions.setFieldValue("password", "");
          const notify = (error) => {
            Notify.create({
              message: error,
              position: "top-right",
              color: "red",
              icon: "fas fa-exclamation",
            });
          };

          if (errors) Object.values(errors).forEach(notify);
          if (message) notify(message);
        })
        .finally(() => (in_request.value = false));
    };

    onMounted(() => {
      if (route.query.code) {
        in_request_code.value = true;
        fixedCode.value = route.query.code;
        setTimeout(() => {
          in_request_code.value = false;
        }, 1000);
      }
    });

    return {
      partner,
      fixedCode,
      in_request_code,
      text_orientation,
      in_request,
      labels,
      schema,
      initialValues,
      onSubmit,
      errorMessage,
    };
  },
};
</script>

<style scoped lang="scss">
.q-field__messages {
  color: red !important;
}
</style>
