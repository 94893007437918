<template>
  <q-card-section
    v-if="group == 0"
    class="justify-center q-ml-md q-mr-md q-mb-sm bg-grey-3"
    style="border-radius: 10px"
  >
    <div
      class="bg-white q-pa-md q-mb-md q-mt-sm text-weight-bolder text-center"
    >
      <p
        class="q-mb-none"
        v-html="
          $t('my_points.reward_pre_paid_card.calc_taxes1', {
            value_1: toMoneyLabel(selectedOption.preco_btdi),
            value_2: selectedOption.preco,
            value_3: toMoneyLabel(selectedOption.frete),
            value_4: toMoneyLabel(
              getTax(
                selectedOption.preco_btdi,
                selectedOption.preco,
                selectedOption.frete
              )
            ),
          })
        "
      />
    </div>
    <prePaidCardInformation :elegible_data="getPrePaidCardElegible" />
    <q-form @submit.prevent="onSubmit(selectedOption)">
      <q-toggle
        v-model="wasRead"
        :label="$t('my_points.reward_gift.confirm_check')"
      /><br />
      <p
        v-if="wasRead == false"
        style="font-size: 9pt"
        class="text-red-8 q-mt-sm q-ml-lg"
        v-html="$t('my_points.reward_gift.alerts.alert_check')"
      />
      <div align="right" class="q-mt-lg">
        <q-btn
          color="black"
          type="submit"
          :disable="
            !wasRead ||
            !getPrePaidCardElegible.elegible ||
            doc_pend.unfilled == 1
          "
          class="button-style"
          :label="$t('resgatar')"
        />
        <q-btn
          flat
          color="primary"
          class="q-ml-sm button-style"
          :label="$t('global.cancel')"
          @click="cardNumber = ''"
          v-close-popup
        />
      </div>
    </q-form>
  </q-card-section>
</template>

<script setup>
import prePaidCardInformation from "./prePaidCardInformation.vue";
import { toMoneyLabel } from "@/shared/helpers/numbersManipulation";
import { useGetters } from "vuex-composition-helpers";
import { ref, defineProps, defineEmits } from "vue";

const props = defineProps({
  option: {
    type: Object,
    required: true,
  },
  data: {
    type: Object,
    required: true,
  },
  doc_pend: {
    type: Object,
    required: true,
  },
  selectedOption: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(["submitForm"]);

const { partner, getPrePaidCardElegible, getRewardInfo } = useGetters({
  partner: "account/getPartnerAccount",
  getPrePaidCardElegible: "rescue_award/getPrePaidCardElegible",
  getRewardInfo: "rescue_award/getRewardInfo"
});

const group = ref(0),
  cardNumber = ref(""),
  wasRead = ref(true);

const onSubmit = () => {
  const conversionReference = getRewardInfo.value?.conversion_reference;
  const { codigo, preco_btdi, preco } = props.selectedOption;

  if ( !conversionReference ) return console.error('conversion_reference sem valor');
  if ( !preco_btdi ) return console.error('preco_btdi sem valor');
  if ( !preco ) return console.error('preco sem valor');
  if ( !codigo ) return console.error('codigo sem valor');

  const amount = parseFloat(preco_btdi * conversionReference);
  const tax = parseFloat((preco_btdi - preco).toFixed(2))

  if ( !amount ) return console.error('Houve um erro ao calcular amount');

  let data = {
    quantity: 1,
    external_id: codigo,
    amount,
    conversion: conversionReference,
    finantial_value: parseFloat(preco),
    tax,
    partner_email: partner.value.email_principal,
    reward_option_id: props.data.option.reward_option_id,
  };

  console.log('ReceberUmNovoCartaoPrePagoPayload', data)

  emit("submitForm", data);
};

const getTax = (preco_btdi, preco, frete) =>
  parseFloat(preco_btdi) - (parseFloat(preco) + parseFloat(frete));
</script>

<style lang="scss" scoped>
.width-container {
  min-width: 60vw;
}
.image-align {
  text-align: center;
}
.image-width {
  width: 70%;
  margin-top: -10px;
}

@media (max-width: 800px) {
  .width-container {
    min-width: 95vw;
  }
  .prod-description {
    width: 100%;
  }
}
</style>
