import moment from "moment";
import {
  dateRule,
  requiredRule,
  requiredRuleForObjects,
} from "@/shared/helpers/customValidation/index";
import { getFormFieldsByStep } from "./formFields";

export const fillingInPreviousData = (partnerData, step) => {
  const fields = getFormFieldsByStep(step).fields;

  fields.forEach((field) => {
    const dataToUse = partnerData[field.name];

    if (field.type === "select") {
      const isOptionObject = typeof field.options[0] === "object";
      const selectedOption = field.options.find((option) =>
        isOptionObject ? option.value === dataToUse : option === dataToUse
      );
      field.value = selectedOption || null;
    } else {
      field.value =
        field.type === "date"
          ? moment(dataToUse).format("YYYY-MM-DD")
          : dataToUse;
    }
  });

  return fields;
};

export function formatData(data) {
  const formattedData = {};

  data.forEach((item) => {
    if (typeof item.value === "object") {
      formattedData[item.name] = item.value.value;
    } else {
      formattedData[item.name] = item.value;
    }
  });

  return formattedData;
}

export const customFields = [
  "custom_field_1",
  "custom_field_2",
  "custom_field_3",
];

export const formatCustomFormData = (data) => {
  // Cria um objeto com os dados do formulÃ¡rio
  const formDataObject = Object.entries(data).reduce((obj, [key, value]) => {
    if (value) {
      obj[key] =
        typeof value === "object" && !Array.isArray(value)
          ? customFields.includes(key)
            ? value.label
            : value.value
          : Array.isArray(value)
          ? value.map((item) => item.label).join(", ")
          : value;
    }
    return obj;
  }, {});

  return formDataObject;
};

export const getCustomValidations = (validations, label, fieldType) => {
  const validationMethods = {
    required: (value) =>
      fieldType === "select"
        ? requiredRuleForObjects(value, label)
        : requiredRule(value, label),
    date: (value) => dateRule(value, label),
  };

  return validations.map((validation) => {
    return validationMethods[validation];
  });
};

export const getPixMask = (type) => {
  if (type === "cpf") return "###.###.###-##";
  if (type === "cnpj") return "##.###.###/####-##";
  if (type === "phone") return "(##) #####-####";
  return undefined;
};
