<template>
  <q-btn dense flat class="no-wrap" v-if="canDisplayCardStores">
    <div class="row no-wrap items-center">
      <q-icon name="shopping_bag" />
      <template v-if="$q.screen.width > 900">
        <span
          class="text-capitalize text-weight-regular row no-wrap"
          :style="$q.platform.is.mobile ? 'font-size: 0.9em' : ''"
        >
          {{ $t("global.my_store") }}
        </span>
        <q-icon name="expand_more" />
      </template>
    </div>

    <q-menu max-width="270px" class="q-mt-lg" :offset="menuOffset" fit>
      <div>
        <card-store
          v-if="links.length === 1"
          :link_store="getLink(links[0])"
          :me_store_params="menu_params"
        />

        <q-tabs
          dense
          v-model="tabs"
          align="center"
          narrow-indicator
          active-color="dark"
          indicator-color="grey-6"
          class="text-grey q-mt-sm"
          v-else-if="links.length > 1"
        >
          <q-tab
            class="text-capitalize"
            v-for="(link, key) in links"
            :key="key"
            :name="'store_' + key"
            :label="getName(link)"
          />
        </q-tabs>
        <div v-else>
          <div class="row" style="max-width: 300px">
            <q-spinner-gears class="q-ma-lg" color="grey" size="2.5em" />
            <div
              class="q-mr-lg text-weight-light text-subtitle2 width-fit-content-flex self-center text-grey"
            >
              {{ $t("partner.store_on_process") }}
            </div>
          </div>
          <q-linear-progress color="grey" indeterminate />
        </div>
        <q-tab-panels
          animated
          v-model="tabs"
          v-if="links.length > 1"
          class="bg-transparent"
        >
          <q-tab-panel
            v-for="(link, index) in links"
            :key="index"
            class="no-scroll"
            :name="'store_' + index"
          >
            <card-store :link_store="getLink(link)" />
          </q-tab-panel>
        </q-tab-panels>
      </div>
    </q-menu>
  </q-btn>
</template>

<script setup>
import { useGetters, useActions } from "vuex-composition-helpers";
import CardStore from "@/modules/main/views/card-store";
import { onMounted, ref, computed } from "vue";
import { useQuasar } from "quasar";

const $q = useQuasar();

const tabs = ref("store_0");

const { links, menu_params, isFlipnetCompany, getPartnerSlug } = useGetters({
    links: "partner/getStoreLinks",
    menu_params: "views/getCardLink",
    isFlipnetCompany: "partner/isFlipnetCompany",
    getPartnerSlug: "partner/getPartnerSlug"
  }),
  { fetchParametersByKeys } = useActions({
    fetchParametersByKeys: "views/fetchParametersByKeys",
  });

// Define o offset do Menu com base no tamanho da tela
const menuOffset = computed(() => {
  return $q.screen.lt.md ? [50, 10] : [0, 10];
});

const canDisplayCardStores = computed(() => !isFlipnetCompany.value && getPartnerSlug.value !== "sodimac")

const getName = (link) => {
    if (typeof link === "string") {
      const url = new URL(link),
        host = url.host.replace("www.", "");
      return host.split(".")[0];
    } else return link.name;
  },
  getLink = (link) => (typeof link === "string" ? link : link.content);

onMounted(async () => {
  await fetchParametersByKeys({
    keys: ["whatsapp_group", "telegram_group"],
  });
});
</script>
