<template>
  <q-card-section class="bg-grey-3 justify-center q-ml-md q-mr-md q-mb-sm">
    <div
      class="bg-white q-pa-md default-rounded q-mt-sm text-weight-bolder text-center"
    >
      <p
        class="q-mb-none"
        v-html="
          $t('my_points.reward_pre_paid_card.calc_taxes2', {
            value_1: toMoneyLabel(selectedOption.preco_btdi),
            value_2: toMoneyLabel(
              selectedOption.preco_btdi - selectedOption.preco
            ),
            value_3: toMoneyLabel(selectedOption.preco),
          })
        "
      />
    </div>
    <div class="q-mt-md q-mb-lg">
      <p
        class="text-weight-bolder"
        style="text-align: center; font-size: 10pt"
        v-html="
          $t('my_points.reward_pre_paid_card.disclaimer', {
            value: toMoneyLabel(selectedOption.preco),
            option: option.title,
          })
        "
      />
    </div>
    <q-form
      @submit.prevent="onSubmit(selectedOption)"
      class="q-gutter-md q-mt-md"
    >
      <div align="center">
        <q-input
          dense
          filled
          type="text"
          ref="ageRef"
          v-model="cardNumber"
          lazy-rules="ondemand"
          label="Número do cartão"
          mask="#### #### #### ####"
          style="max-width: 350px"
          :rules="[(value) => checkPrePaidCardNumber(value, card_prefix)]"
        />

      </div>
      <q-toggle
        v-model="wasRead"
        :label="$t('my_points.reward_gift.confirm_check')"
      /><br />
      <p
        v-if="wasRead == false"
        style="font-size: 9pt"
        class="text-red-8 q-mt-sm q-ml-lg"
        v-html="$t('my_points.reward_gift.alerts.alert_check')"
      />
      <div align="right" class="q-mt-lg">
        <q-btn
          color="black"
          type="submit"
          :disable="!wasRead"
          class="button-style"
          :label="$t('resgatar')"
        />
        <q-btn
          flat
          v-close-popup
          color="primary"
          @click="cardNumber = ''"
          class="q-ml-sm button-style"
          :label="$t('global.cancel')"
        />
      </div>
    </q-form>
  </q-card-section>
</template>

<script setup>
import { checkPrePaidCardNumber } from "@/shared/helpers/validationHelper";
import { toMoneyLabel } from "@/shared/helpers/numbersManipulation";
import { useGetters } from "vuex-composition-helpers";
import { ref, defineProps, defineEmits } from "vue";

const props = defineProps({
  option: {
    type: Object,
    required: true,
  },
  data: {
    type: Object,
    required: true,
  },
  selectedOption: {
    type: Object,
    required: true,
  },
  card_prefix: {
    type: Array,
    required: true,
  },
})

console.log('rewardINfo', props.option, props.data)

const emit = defineEmits(["submitForm"]);

const { partner, getRewardInfo } = useGetters({
  partner: "account/getPartnerAccount",
  getRewardInfo: "rescue_award/getRewardInfo"
});

const cardNumber = ref(""),
  wasRead = ref(true);

const onSubmit = () => {
  const conversionReference = getRewardInfo.value?.conversion_reference;
  const { preco_btdi, preco, codigo } = props.selectedOption;

  if ( !conversionReference ) return console.error('conversion_reference sem valor');
  if ( !preco_btdi ) return console.error('preco_btdi sem valor');
  if ( !preco ) return console.error('preco sem valor');
  if ( !codigo ) return console.error('codigo sem valor');


  const amount = parseFloat(preco_btdi * conversionReference);
  const tax = parseFloat((preco_btdi - preco).toFixed(2))

  if ( !amount ) return console.error('Houve um erro ao calcular amount');

  let data = {
    quantity: 1,
    external_id: codigo,
    amount,
    conversion: conversionReference,
    finantial_value: parseFloat(preco),
    tax,
    partner_email: partner.value.email_principal,
    acesso_card: cardNumber.value.replace(/\s/g, ""),
    reward_option_id: props.data.option.reward_option_id,
  };

  console.log('RecarregarCartãoPréPagoPayload', data)

  emit("submitForm", data);
};
</script>

<style lang="scss" scoped>
.width-container {
  min-width: 60vw;
}
.image-align {
  text-align: center;
}
.image-width {
  width: 70%;
  margin-top: -10px;
}

@media (max-width: 800px) {
  .width-container {
    min-width: 95vw;
  }
  .prod-description {
    width: 100%;
  }
}
</style>
